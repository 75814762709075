<template>
  <v-app>
    <v-main>
      <div style="height: 500px;align-items: center;display: flex;justify-content: center">Moin</div>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: 'App',

  data: () => ({
    //
  }),
}
</script>
